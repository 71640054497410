<template>
  <v-row>
    <v-col class="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4 mx-auto">
      <ValidationObserver ref="form" v-slot="{ invalid }">
        <v-form @submit.prevent="save" autocomplete="off">
          <v-card>
            <v-card-title>
              <v-icon large left>mdi-account-box</v-icon>
              <span class="title">
                {{ $capitalize($tc("model.profile_page_title")) }}
              </span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="text-center">
                  <user-avatar
                    v-if="avatar"
                    size="100"
                    font_size="35"
                    :user_name="form.name"
                    :avatar_url="avatar"
                  />
                  <user-avatar
                    v-else
                    size="100"
                    font_size="35"
                    :user_name="form.name"
                    :avatar_url="user.avatar"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="!avatar" class="text-center">
                  <v-btn
                    @click="openImageGetter"
                    small
                    outlined
                    color="primary"
                    style="margin-top: -15px"
                    >
                    {{ $capitalize($tc("model.profile_change_photo")) }}
                    </v-btn
                  >
                </v-col>
                <v-col v-else class="text-center">
                  <v-btn
                    @click="avatar = null"
                    small
                    outlined
                    style="margin-top: -15px"
                    class="mr-1"
                    color="grey darken-1"
                    :disabled="loading_avatar"
                  >
                    <v-icon small left>mdi-refresh</v-icon>{{ $capitalize($tc("model.profile_button_reset")) }}
                  </v-btn>
                  <v-btn
                    @click="updateImage"
                    small
                    color="primary"
                    style="margin-top: -15px"
                    class="mr-1"
                    :loading="loading_avatar"
                  >
                    <v-icon small left>mdi-arrow-up</v-icon>{{ $capitalize($tc("model.profile_button_submit")) }}
                  </v-btn>
                </v-col>
              </v-row>
              <input
                @change="changeAvatar($event)"
                type="file"
                name
                id
                ref="avatar"
                v-show="false"
                accept="image/*"
              />
              <ValidationProvider
                name="nome"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  :label="
                    $capitalize($tc('model.profile_full_name'))
                  "
                  name="nome"
                  type="text"
                  v-model="form.name"
                  :error-messages="errors"
                  :disabled="form_loading"
                />
              </ValidationProvider>
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <v-text-field
                  :label="
                    $capitalize($tc('model.profile_email'))
                  "
                  name="email"
                  type="email"
                  :value="user.email"
                  :error-messages="errors"
                  disabled
                />
              </ValidationProvider>
              <ValidationProvider
                name="senha atual"
                :rules="'required'"
                v-slot="{ errors }"
              >
                <v-text-field
                  :label="
                    $capitalize($tc('model.profile_current_password'))
                  "
                  name="senha_atual"
                  v-model="form.senha_atual"
                  :error-messages="errors"
                  :disabled="form_loading"
                  :type="show2 ? 'text' : 'password'"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show2 = !show2"
                  autocomplete="off"
                />
              </ValidationProvider>
              <ValidationProvider
                name="senha"
                :rules="form.id ? '' : 'min:6'"
                v-slot="{ errors }"
              >
                <v-text-field
                  id="password"
                  :label="
                    $capitalize($tc('model.profile_new_password'))
                  "
                  name="senha"
                  v-model="form.senha"
                  :error-messages="errors"
                  :disabled="form_loading"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  persistent-hint
                  :hint="
                    $capitalize($tc('model.profile_new_password_field_hint'))
                  "
                  autocomplete="off"
                />
              </ValidationProvider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn type="submit" :loading="form_loading" color="primary"
                >
                  {{ $capitalize($tc("model.profile_button_save")) }}
                </v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-col>
  </v-row>
</template>

<script>
import { update as updateProfile, updateAvatar } from "@/services/profile";
import UserAvatar from "@/components/UserAvatar";

export default {
  data: () => ({
    form: {},
    form_loading: false,
    show: false,
    show2: false,
    avatar: null,
    avatar_file: null,
    loading_avatar: false,
  }),
  components: { UserAvatar },
  methods: {
    async save() {
      const validation = await this.$refs.form.validate();
      if (!validation) return;

      this.form_loading = true;

      let payload = {
        name: this.form.name,
        old_password: this.form.senha_atual,
      };

      if (this.form.senha != "") {
        payload.password = this.form.senha;
      }

      try {
        const response = await updateProfile(payload);

        if (response && response.password_changed) {
          await this.$store.dispatch("auth/login", {
            auth: {
              username: this.user.email,
              password: payload.password,
            }
          });
        } else if (response && !response.password_changed) {
          await this.$store.dispatch("auth/refreshTokens");
        }

        this.$store.commit("sendMessage", {
          text: "Perfil atualizado com sucesso!",
          color: "success",
        });

        this.form.senha_atual = "";
        this.form.senha = "";
        this.$refs.form.reset();
      } catch (e) {
        this.form_loading = false;
        this.$store.commit("sendMessage", {
          text: e.response.data.message,
          color: "red",
        });
      } finally {
        this.form_loading = false;
      }
    },
    async updateImage() {
      this.loading_avatar = true;
      let data = new FormData();
      data.append("avatar", this.avatar_file);

      try {
        await updateAvatar(data);
        await this.$store.dispatch("auth/refreshTokens");
        this.avatar = null;
        this.$store.commit("sendMessage", {
          text: "Avatar atualizado com sucesso!",
          color: "success",
        });
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: e.response.data.message,
          color: "red",
        });
      } finally {
        this.loading_avatar = false;
      }
    },
    openImageGetter() {
      this.$refs.avatar.click();
    },
    changeAvatar(event) {
      this.avatar = this.getUrlPreview(event.target.files[0]);
      this.avatar_file = event.target.files[0];
    },
    getUrlPreview(image) {
      return URL.createObjectURL(image);
    },
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"];
    },
  },
  created() {
    this.form = { ...this.user };
    this.form.senha = "";
  },
};
</script>

<style lang="scss">
.avatar {
  -webkit-box-shadow: inset 0px 0px 56px -10px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: inset 0px 0px 56px -10px rgba(0, 0, 0, 0.43);
  box-shadow: inset 0px 0px 56px -10px rgba(0, 0, 0, 0.43) !important;
}
</style>