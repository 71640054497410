import Vue from 'vue';


export async function update(payload) {
  const response = await  Vue.prototype.$http.post("/profile", payload);

  return response.data;
}

export async function updateAvatar(payload) {
  const response = await  Vue.prototype.$http.post("/profile/avatar", payload);

  return response.data;
}